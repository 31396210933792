declare global {
    interface Window {
        grecaptcha: {
            ready: (callback: () => void) => void;
            execute: (siteKey: string, options: { action: string }) => Promise<string>;
        };
    }
}

/**
 * Class responsible for handling Google Recaptcha v3 loading and form submission.
 */
export class RecaptchaFormHandler {
    private recaptchaLoaded: boolean;
    private readonly recaptchaPublicKey: string | null;

    constructor() {
        this.recaptchaLoaded = false;
        this.recaptchaPublicKey = this.getRecaptchaPublicKey();

        if (!this.recaptchaPublicKey) throw new Error("Recaptcha public key not found in meta tags.");

        this.loadRecaptcha();
        this.observeMutations();
    }

    /**
     * Retrieves the Recaptcha public key from a meta tag.
     * @returns {string} The Recaptcha public key.
     */
    private getRecaptchaPublicKey(): string | null {
        const metaTag = document.querySelector('meta[name="recaptcha-public"]');
        return metaTag ? metaTag.getAttribute("content") : null;
    }

    private loadRecaptcha() {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaPublicKey}`;
        script.onload = () => {
            this.recaptchaLoaded = true;

            window.grecaptcha.ready(() => {
                this.attachSubmitHandlers();
                this.refreshTokens();
            });
        };

        document.head.appendChild(script);
    }

    /**
     * Attach the submit handlers and initialize the reCaptcha token.
     */
    private attachSubmitHandlers() {
        document.querySelectorAll("[data-recaptcha-form]").forEach((form) => {
            this.insertRecaptchaTokenField(form as HTMLFormElement);
            this.addSubmitHandler(form as HTMLFormElement);
        });
    }

    /**
     * Handle form submissions.
     *
     * @param formElement
     */
    private addSubmitHandler(formElement: HTMLFormElement) {
        let isSubmitting = false;

        formElement.addEventListener("submit", (event) => {
            if (isSubmitting) return;
            isSubmitting = true;

            event.preventDefault();

            const formId = formElement.id;
            const action = formElement.getAttribute("data-recaptcha-action");

            if (!action || !this.recaptchaLoaded) {
                console.error("ReCaptcha not loaded or action not defined for form:", formId);
                isSubmitting = false;
                return;
            }

            // Execute reCAPTCHA and get the token
            window.grecaptcha.execute(this.recaptchaPublicKey!, {action: action})
                .then((token: string) => {
                    const tokenField = formElement.querySelector(`input[name="_recaptcha_token_${formId}"]`) as HTMLInputElement;

                    if (!tokenField) {
                        console.error("Token field not found for form:", formId);
                        isSubmitting = false;
                        return;
                    }

                    tokenField.value = token;
                    isSubmitting = false;

                })
                .catch((error) => {
                    console.error("Error executing ReCaptcha:", error);
                    isSubmitting = false;
                });
        });
    }

    /**
     * Insert the reCaptcha token field to send with the form data.
     *
     * @param form
     */
    private insertRecaptchaTokenField(form: HTMLFormElement) {
        // Start refreshing the token for this form
        const formId = form.id;
        const action = form.getAttribute("data-recaptcha-action");

        if (!formId || !action) {
            console.warn("New form added without required attributes:", form);
            return;
        }

        // Create a hidden token field if not present
        if (!form.querySelector(`input[name="_recaptcha_token_${formId}"]`)) {
            const tokenFieldId = `recaptcha_token_${formId}`;
            form.insertAdjacentHTML(
                "beforeend",
                `<input type="hidden" name="_recaptcha_token_${formId}" id="${tokenFieldId}">`
            );
        }
    }

    /**
     * Automatically refresh the form token every 90 seconds.
     */
    private refreshTokens() {

        setInterval(() => {

            if (!this.recaptchaLoaded) return;

            document.querySelectorAll("[data-recaptcha-form]").forEach((form) => {
                const formId = form.id;
                const action = form.getAttribute("data-recaptcha-action");

                if (!action) return;

                const tokenField = form.querySelector(`input[name="_recaptcha_token_${formId}"]`) as HTMLInputElement;

                if (!tokenField) return;

                window.grecaptcha.execute(this.recaptchaPublicKey!, {action: action}).then((token: string) => {
                    tokenField.value = token;
                }).catch((error) => {
                    console.error("Error refreshing ReCaptcha token:", error);
                });
            });
        }, 90000);
    }

    /**
     * Sets up an Intersection Observer to monitor when forms are visible on the page.
     * It loads Recaptcha when a form becomes visible and then stops observing.
     */
    private observeMutations() {
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node instanceof HTMLElement && node.matches("[data-recaptcha-form]")) {

                        this.insertRecaptchaTokenField(node as HTMLFormElement);
                        this.addSubmitHandler(node as HTMLFormElement);
                    }
                });
            });
        });

        // Observe changes in the DOM
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    }
}

